import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { Appointment, AppointmentCreateDto } from '../models/appointments.models'

export const appointmentCreateAction = createActionGroup({
  source: 'appointmentCreate',
  events: {
    'Request Appointment Create': props<{ dto: AppointmentCreateDto }>(),
    'Request Create Appointment Success': props<{ appointment: Appointment }>(),
    'Request Create Appointment Failed': (error: any) => ({ error }),
    'Request Create Appointment Clear State': emptyProps(),
  },
})

export const appointmentListByCreatorIdAction = createActionGroup({
  source: 'appointmentListByCreatorId',
  events: {
      'Request Appointment List By Creator Id': props<{ created_by: number }>(),
      'Request Appointment List By Creator Id Success': props<{ appointments: Appointment[] }>(),
      'Request Appointment List By Creator Id Failed': (error: any) => ({ error }),
      'Request Appointment List By Creator Id Clear State': emptyProps(),
    },

})
