import { createFeature, createReducer, on } from '@ngrx/store'

import { appointmentListByCreatorIdAction, appointmentCreateAction } from './actions'
import { AppointmentCreateInitialState, AppointmentListByCreatorIdInitialState } from '../models/appointments.models'

const initialAppointmentState: AppointmentCreateInitialState = {
  appointmentCreateIsLoading: false,
  appointmentCreateError: '',
  appointment: null,
}

const initialAppointmentListByCreatorIdState: AppointmentListByCreatorIdInitialState = {
  appointmentListByCreatorIdIsLoading: false,
  appointmentListByCreatorIdError: '',
  appointments: [],
}

const appointmentCreateFeature = createFeature({
  name: 'appointmentCreate',
  reducer: createReducer(
    initialAppointmentState,
    on(appointmentCreateAction.requestAppointmentCreate, (state) => ({ ...state, appointmentCreateIsLoading: true })),
    on(appointmentCreateAction.requestCreateAppointmentSuccess, (state, event) => ({
      ...state,
      appointmentCreateIsLoading: false,
      appointment: event.appointment,
    })),
    on(appointmentCreateAction.requestCreateAppointmentFailed, (state, event) => ({
      ...state,
      appointmentCreateIsLoading: false,
      appointmentCreateError: event.error,
      appointment: null,
    })),
    on(appointmentCreateAction.requestCreateAppointmentClearState, (state, event) => ({
      ...initialAppointmentState,
    })),
  ),
})

const appointmentListByCreatorIdFeature = createFeature({
  name: 'appointmentListByCreatorId',
  reducer: createReducer(
    initialAppointmentListByCreatorIdState,
    on(appointmentListByCreatorIdAction.requestAppointmentListByCreatorId, (state) => ({ ...state, appointmentListByCreatorIdIsLoading: true })),
    on(appointmentListByCreatorIdAction.requestAppointmentListByCreatorIdSuccess, (state, event) => ({
      ...state,
      appointmentListByCreatorIdIsLoading: false,
      appointments: event.appointments,
    })),
    on(appointmentListByCreatorIdAction.requestAppointmentListByCreatorIdFailed, (state, event) => ({
      ...state,
      appointmentListByCreatorIdIsLoading: false,
      appointmentListByCreatorIdError: event.error,
      appointments: [],
    })),
    on(appointmentListByCreatorIdAction.requestAppointmentListByCreatorIdClearState, (state, event) => ({
      ...initialAppointmentListByCreatorIdState,
    })),
  ),
})

// const employeeMeetingResponseFeature = createFeature({
//   name: 'EmployeeMeetingResponse',
//   reducer: createReducer(
//     initialEmployeeMeetingResponseState,
//     on(employeeMeetingResponseAction.requestEmployeeMeetingResponse, (state) => ({ ...state, isLoading: true })),
//     on(employeeMeetingResponseAction.requestEmployeeMeetingResponseSuccess, (state, event) => ({
//       ...state,
//       isLoading: false,
//       employeeMeeting: event.employeeMeeting,
//     })),
//     on(employeeMeetingResponseAction.requestEmployeeMeetingResponseFailed, (state, event) => ({
//       ...state,
//       isLoading: false,
//       error: event.error,
//       employeeMeeting: null,
//     })),
//     on(employeeMeetingResponseAction.requestEmployeeMeetingResponseClearState, (state, event) => ({
//       ...initialEmployeeMeetingResponseState,
//     })),
//   ),
// })

export const {
  name: appointmentCreateFeatureKey,
  reducer: appointmentCreateReducer,
  selectAppointmentCreateIsLoading,
  selectAppointmentCreateError,
  selectAppointment,
} = appointmentCreateFeature

export const {
  name: appointmentListByCreatorIdFeatureKey,
  reducer: appointmentListByCreatorIdReducer,
  selectAppointmentListByCreatorIdIsLoading,
  selectAppointmentListByCreatorIdError,
  selectAppointments,
} = appointmentListByCreatorIdFeature

// export const {
//   name: employeeMeetingResponseFeatureKey,
//   reducer: employeeMeetingResponseReducer,
//   selectEmployeeResponseIsLoading,
//   selectEmployeeResponseError,
//   selectEmployeeResponseMeeting,
// } = employeeMeetingResponseFeature
